import capitalizeAll from '@zola-helpers/client/dist/es/transformers/capitalizeAll';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';

import type { NavigationClicked } from '@zola/tracking-contracts/src/events';
import type { SecondaryNavItem } from '@zola-helpers/client/dist/es/constants/navConstants';

/**
 * Helpers for the new trackNavigationClicked from tracking-contracts
 * https://github.com/NewAmsterdamLabs/tracking-contracts
 */

// Transforms copies to correct values for the trackNavigationClicked navigation_level_1 property
// 1. Capitalizes all characters
// 2. Strips apostrophes, commas, and trailing spaces
// 3. Replaces spaces and dashes with underscores
// 4. Returns empty string if label is not a string
export const transformNavigationLevel = (label: string | JSX.Element): string => {
  return typeof label === 'string'
    ? capitalizeAll(label)
        .trim()
        .replace(/[',]/g, '')
        .replace(/[ -]|'/g, '_')
    : '';
};

const maybeAddNavigationLevelProperties = (level: number, node?: SecondaryNavItem) =>
  node
    ? {
        [`navigation_level_${level}`]: transformNavigationLevel(node.title),
        [`navigation_level_${level}_position`]: node.position || 1,
      }
    : {};

export type UnsanitizedNavigationClicked = {
  firstNode?: SecondaryNavItem;
  secondNode?: SecondaryNavItem;
  thirdNode?: SecondaryNavItem;
  fourthNode?: SecondaryNavItem;
  navigationType: NavigationClicked['navigation_type'];
  disableTracking?: boolean;
};

export const sanitizeNavigationClickedData = ({
  firstNode,
  secondNode,
  thirdNode,
  fourthNode,
  navigationType,
}: UnsanitizedNavigationClicked): NavigationClicked => {
  const filteredNodes = [firstNode, secondNode, thirdNode, fourthNode].filter(
    (n) => n !== undefined
  );
  const firstFilteredNode = filteredNodes[0];
  const lastFilteredNode = filteredNodes[filteredNodes.length - 1];
  return {
    business_category: lastFilteredNode?.businessCategory || 'UNATTRIBUTED',
    business_unit: lastFilteredNode?.businessUnit || 'UNATTRIBUTED',
    navigation_level_1: transformNavigationLevel(firstFilteredNode?.title || ''),
    navigation_level_1_position: firstFilteredNode?.position || 1,
    ...maybeAddNavigationLevelProperties(2, filteredNodes[1]),
    ...maybeAddNavigationLevelProperties(3, filteredNodes[2]),
    ...maybeAddNavigationLevelProperties(4, filteredNodes[3]),
    navigation_type: navigationType,
  };
};

export const sanitizeAndTrackNavigationClicked = (
  unsanitizedData: UnsanitizedNavigationClicked
): void => {
  if (unsanitizedData.disableTracking) return;
  trackNavigationClicked(sanitizeNavigationClickedData(unsanitizedData));
};
