import { BusinessUnitComponent } from '@zola-helpers/client/dist/es/@types';

export default function getBusinessUnitComponent(): BusinessUnitComponent {
  // Describes which onboard flow we push the user into.
  // Check for explicit business unit component first.
  if (window.zolaNav && window.zolaNav.businessUnitFeature) {
    return window.zolaNav.businessUnitFeature;
  }

  // Check from location context.
  const path = window.location.pathname;

  if (
    path.indexOf('wedding-registry') > -1 ||
    (window.zolaNav && window.zolaNav.isRegistrySignup)
  ) {
    return BusinessUnitComponent.REGISTRY;
  }

  if (path.indexOf('wedding-planning/digital/save-the-date') > -1) {
    return BusinessUnitComponent.DIGITAL_SAVE_THE_DATE;
  }

  if (path.indexOf('wedding-planning/save-the-date') > -1) {
    return BusinessUnitComponent.SAVE_THE_DATE;
  }

  if (path.indexOf('wedding-planning/change-the-date') > -1) {
    return BusinessUnitComponent.CHANGE_THE_DATE;
  }

  if (path.indexOf('/wedding-planning/thank-you-cards') > -1) {
    return BusinessUnitComponent.THANK_YOU_CARDS;
  }

  if (
    path.indexOf('/wedding-planning/programs') > -1 ||
    path.indexOf('/wedding-planning/place-cards') > -1 ||
    path.indexOf('/wedding-planning/table-numbers') > -1 ||
    path.indexOf('/wedding-planning/menus') > -1
  ) {
    return BusinessUnitComponent.DAY_OF_PAPER;
  }

  if (path.indexOf('/wedding-planning/holiday-cards') > -1) {
    return BusinessUnitComponent.HOLIDAY;
  }

  if (path.indexOf('/wedding-planning/bridal-shower') > -1) {
    return BusinessUnitComponent.WEDDING_SHOWER_INVITATION;
  }

  if (
    path.indexOf('wedding-planning/invites-paper') > -1 || // old path
    path.indexOf('wedding-planning/paper') > -1 ||
    path.indexOf('wedding-planning/universal') > -1 ||
    path.indexOf('wedding-planning/invitations') > -1 ||
    path.indexOf('/wedding-planning/enclosures') > -1 ||
    path.indexOf('/wedding-planning/suites/shop') > -1 ||
    path.indexOf('/wedding-planning/disney') > -1 ||
    (window.zolaNav && window.zolaNav.isInvitationsSignup)
  ) {
    return BusinessUnitComponent.INVITATIONS;
  }

  if (path.indexOf('wedding-planning/guests') > -1) {
    return BusinessUnitComponent.WEDDING_GUEST_LIST;
  }

  if (path.indexOf('/wedding-planning/checklist') > -1) {
    return BusinessUnitComponent.WEDDING_CHECKLIST;
  }

  if (path === '/wedding-planning') {
    return BusinessUnitComponent.WEDDING_PLANNING;
  }

  if (
    path.indexOf('/wedding-vendors') === 0 ||
    path.indexOf('/inspiration') === 0 ||
    path.indexOf('/inspire') === 0
  ) {
    return BusinessUnitComponent.MARKETPLACE;
  }

  if (path.indexOf('wedding') > -1 || (window.zolaNav && window.zolaNav.isWeddingSignup)) {
    return BusinessUnitComponent.WEDDING_WEBSITE;
  }

  if (window.location.href.indexOf('homestore') > -1) {
    return BusinessUnitComponent.STORE;
  }

  return BusinessUnitComponent.REGISTRY;
}
