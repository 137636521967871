import React, { useEffect } from 'react';
import cx from 'classnames';
import type { WZolaActivity } from '@zola/svc-web-api-ts-client';
import NotificationItem from '@zola/zola-ui/src/components/NotificationItem';
import mapItemTypeToIcon from '@zola/zola-ui/src/components/NotificationItem/notificationMapper';
import {
  trackCtaClicked,
  trackCtaViewed,
  CTA_TYPES,
} from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';
import styles from './NotificationListPanel.module.less';

interface NotificationListPanel {
  activities: WZolaActivity[];
  handleMenuItemClick: (groupId: string) => void;
  isPostAuthNav3Enabled: boolean;
}

const WEEK_IN_MS = 1000 * 60 * 60 * 24 * 7;

const NotificationListPanel: React.FC<NotificationListPanel> = ({
  activities,
  handleMenuItemClick,
  isPostAuthNav3Enabled,
}) => {
  const newNotification: WZolaActivity[] = [];
  const oldNotification: WZolaActivity[] = [];
  activities.forEach((activity) => {
    if (activity.group_updated_at) {
      // This is a legit and reasonable way to get a date diff
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (new Date() - new Date(activity.group_updated_at) <= WEEK_IN_MS) {
        newNotification.push(activity);
      } else {
        oldNotification.push(activity);
      }
    }
  });

  const mapItemToNotificationItem = (item: WZolaActivity) => {
    const TrackedNotificationItem: React.FC = () => {
      const ctaEventData = {
        business_unit: 'CORE',
        cta_type: CTA_TYPES.LINK,
        group_id: 'NOTIFICATION_FEED',
        cta_id: `notification-type-${item.type?.toString().toLowerCase().replace(/_/g, '-')}`,
        position: 0,
      };

      useEffect(() => {
        trackCtaViewed(ctaEventData);
      }, [ctaEventData]);

      return (
        <NotificationItem
          {...(isPostAuthNav3Enabled && { css: { padding: '16px 20px' } })}
          message={item.message}
          time={item.time}
          read={item.read}
          link={item.link}
          iconComponent={mapItemTypeToIcon(item.type?.toString())}
          onClick={() => {
            handleMenuItemClick(item.stream_group_id as string);
            trackCtaClicked(ctaEventData);
          }}
        />
      );
    };
    return <TrackedNotificationItem key={item.stream_group_id} />;
  };

  return (
    <article
      className={cx(styles.panel, {
        [styles['panel--postAuthNav3']]: isPostAuthNav3Enabled,
      })}
    >
      {newNotification.length > 0 ? (
        <section id="new-notification">
          <h3 className={styles.sectionHeader}>New</h3>
          {newNotification.map(mapItemToNotificationItem)}
        </section>
      ) : null}
      {oldNotification.length > 0 ? (
        <section id="old-notification">
          <h3 className={cx(styles.sectionHeader, styles.sectionHeaderWithBorder)}>Earlier</h3>
          {oldNotification.map(mapItemToNotificationItem)}
        </section>
      ) : null}
    </article>
  );
};

export default NotificationListPanel;
