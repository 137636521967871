import styled from '@emotion/styled';
import { css } from '@emotion/react';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import ZINDEX from '@zola/zola-ui/src/styles/emotion/zIndex';
import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';
import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';

export const Nav = styled.nav`
  background-color: ${COLORS3.WHITE_100};
  z-index: ${ZINDEX.super};
  border-bottom: 1px solid ${COLORS3.BLACK_030};
`;

export const TopNavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${SPACING.S16} ${SPACING.MD};
  ${MEDIA_QUERY_V2.TABLET} {
    padding: ${SPACING.S16} ${SPACING.LG};
  }
`;

export const TopNavBarLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING.XS};
`;

export const StyledLinkV2 = styled(LinkV2)`
  display: flex;
  align-items: center;
`;

export const StyledZolaLogo = styled(ZolaLogo)`
  height: 16px;
  width: 104px;
`;

export const SharedIconStyles = css`
  color: ${COLORS3.BLACK_100} !important; // always black, even on hover or click
  margin-left: 16px;
  height: 24px;
`;

export const SharedIconStylesWithBadge = css`
  ${SharedIconStyles}
  position: relative;
  display: flex;
  align-items: center;
  .badge {
    background-color: ${COLORS3.ROSE_100};
    position: absolute;
    right: -10px;
    top: -4px;
    border-radius: 10px;
    color: ${COLORS3.WHITE_100};
    display: inline-block;
    font-size: 12px;
    font-weight: ${FONT.FONT_WEIGHT_BOLD};
    line-height: 1;
    min-width: 10px;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }
`;

export const UniversalSearchContainer = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  background: ${COLORS3.WHITE_100};
  padding: ${SPACING.S24};
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
`;

export const Collapse = styled.div<{ collapseOnScroll?: boolean }>`
  overflow-y: hidden;
  transition: all 300ms;
  max-height: ${({ collapseOnScroll }) => (collapseOnScroll ? 0 : '52px')};
  opacity: ${({ collapseOnScroll }) => (collapseOnScroll ? 0 : 1)};
`;
