import { useEffect } from 'react';

import {
  PrimaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';

import { WEB_ZOLA_CONTENT_CLASSNAME } from 'util/constants/documentConstants';

/**
 * Sets additional style adjustments for web-zola, including left padding for
 * desktop vertical nav and nav height. While nav height is usually set from
 * each respective web app, web-zola is an exception as we emit INIT_NAV_DATA
 * from web-nav for web-zola pages.
 */
const useSetWebZolaNavStyles = ({
  primaryLinkId,
  secondaryNavData,
  tertiaryNavData,
  isPostAuth,
}: {
  primaryLinkId?: '' | PrimaryNavIdentifier;
  secondaryNavData: SecondaryNavItem[];
  tertiaryNavData: SecondaryNavItem[];
  isPostAuth?: boolean;
}): void => {
  useEffect(() => {
    const webZolaContent = document.getElementsByClassName(
      WEB_ZOLA_CONTENT_CLASSNAME
    )?.[0] as unknown as HTMLElement;

    if (!webZolaContent) return;
    const webZolaFooter = document.getElementById('website-footer');
    const header = document.getElementById('header');
    const isNavHidden = document.getElementsByClassName(
      'hidden-nav'
    )?.[0] as unknown as HTMLElement;

    const hasSecondaryData = secondaryNavData.length > 0;
    const hasTertiaryData = tertiaryNavData.length > 0;
    const showNavWithSecondary = hasSecondaryData && !hasTertiaryData;
    const showNavWithTertiary = hasTertiaryData && !hasSecondaryData;
    const showNavWithSecondaryAndTertiary = hasSecondaryData && hasTertiaryData;
    const showDefaultNav = !showNavWithSecondaryAndTertiary;
    const isDesktopScreen = isDesktop();

    // Sets left padding to account for desktop vertical nav on pages where nav isn't hidden
    if (!isNavHidden) {
      webZolaContent.style.paddingLeft = isDesktopScreen && isPostAuth ? '74px' : '0';
      if (webZolaFooter) {
        webZolaFooter.style.paddingLeft = isDesktopScreen && isPostAuth ? '74px' : '0';
        if (isPostAuth) {
          webZolaFooter.classList.add('post-auth-footer');
        } else {
          webZolaFooter.classList.add('pre-auth-footer');
        }
      }
    }

    // Sets correct nav height
    if (!header) return;
    if (isPostAuth) {
      if (showNavWithSecondary) {
        header.style.height = isDesktopScreen ? '172px' : '96px';
      } else if (showNavWithTertiary) {
        header.style.height = isDesktopScreen ? '188px' : '108px';
      } else if (showNavWithSecondaryAndTertiary) {
        header.style.height = isDesktopScreen ? '228px' : '148px';
      } else if (showDefaultNav) {
        header.style.height = isDesktopScreen ? '168px' : '56px';
      }
    } else if (showNavWithSecondary) {
      header.style.height = isDesktopScreen ? '189px' : '108px';
    } else {
      header.style.height = isDesktopScreen ? '133px' : '56px';
    }
  }, [isPostAuth, primaryLinkId, secondaryNavData, tertiaryNavData]);
};

export default useSetWebZolaNavStyles;
