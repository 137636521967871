import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  VendorTypeEnum,
  getVendorTaxonomyKeyFromVendorType,
} from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { getCookie } from '@zola-helpers/client/dist/es/util/storage';

import { showModal } from 'actions/ModalActions';
import { UserContext } from '@zola-helpers/client/dist/es/@types';
import { BookingModalProps } from '../utils/types';
import {
  getUserPrompt,
  getConnectedInquiries,
  snoozeModal,
  userEligibleForModal,
} from '../utils/helpers';

let hasCheckedEligibility = false;

export const useBookingModal = (userContext: UserContext): void => {
  const dispatch = useDispatch();

  const maybeShowModal = useCallback(async () => {
    if (userEligibleForModal(userContext)) {
      const { eligibleForPrompt, metadata } = await getUserPrompt('INQUIRY_MAINTENANCE');
      if (eligibleForPrompt && metadata?.vendorType) {
        const { entities } = await getConnectedInquiries(metadata.vendorType as VendorTypeEnum);

        const existingModalOnPage = Boolean(
          document.querySelectorAll(
            '#modal, #z-modal-overlay-v2, #react-joyride-portal, [role="dialog"]'
          ).length
        );
        if (entities.length && !existingModalOnPage) {
          const props: BookingModalProps = {
            initialInquiries: entities,
            vendorTaxonomyKey: getVendorTaxonomyKeyFromVendorType(
              metadata.vendorType as VendorTypeEnum
            ),
            closeCallback: snoozeModal,
          };
          dispatch(showModal('BOOKING_MODAL', props, { v2Styles: true }));
        }
      }
    }
  }, [dispatch, userContext]);

  useEffect(() => {
    const isAutomatedTest = getCookie('automatedTest') === 'true';
    if (!isAutomatedTest && !hasCheckedEligibility && userContext.object_id) {
      hasCheckedEligibility = true;
      maybeShowModal().catch(() => null);
    }
  }, [maybeShowModal, userContext]);
};

export default useBookingModal;
