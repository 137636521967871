import { getSessionStorage } from '@zola-helpers/client/dist/es/util/storage';
import { BusinessUnit, BusinessUnitComponent } from '@zola-helpers/client/dist/es/@types';
import { trackOnboardingStarted } from '@zola-helpers/client/dist/es/tracking/onboard/onboardEvents';
import detectBusinessUnit from '@zola-helpers/client/dist/es/util/detectBusinessUnit';
import detectBusinessUnitFeature from 'util/detectBusinessUnitFeature';
import NAV_DATA, {
  PrimaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';

const onDirectSignup = () =>
  window?.zolaPaper?.openSignUpModal instanceof Function
    ? window.zolaPaper.openSignUpModal()
    : null;

export const mapComponentToOnboardFlow: Record<BusinessUnitComponent, string | undefined> = {
  [BusinessUnitComponent.WEDDING_WEBSITE]: '/wedding/onboard/wedding-website',
  [BusinessUnitComponent.WEDDING_GUEST_LIST]: '/wedding/onboard/wedding-guest-list',
  [BusinessUnitComponent.WEDDING_CHECKLIST]: '/wedding/onboard/wedding-checklist',
  [BusinessUnitComponent.WEDDINGS]: '/wedding/onboard/wedding-planning',
  [BusinessUnitComponent.WEDDING_PLANNING]: '/wedding/onboard/wedding-planning',
  [BusinessUnitComponent.DIGITAL_SAVE_THE_DATE]:
    '/wedding/onboard/wedding-paper/DIGITAL_SAVE_THE_DATE',
  [BusinessUnitComponent.INVITATIONS]: '/wedding/onboard/wedding-paper/INVITATIONS',
  [BusinessUnitComponent.SAVE_THE_DATE]: '/wedding/onboard/wedding-paper/SAVE_THE_DATE',
  [BusinessUnitComponent.CHANGE_THE_DATE]: '/wedding/onboard/wedding-paper/CHANGE_THE_DATE',
  [BusinessUnitComponent.DAY_OF_PAPER]: '/wedding/onboard/wedding-paper/DAY_OF_PAPER',
  [BusinessUnitComponent.THANK_YOU_CARDS]: '/wedding/onboard/wedding-paper/THANK_YOU_CARDS',
  [BusinessUnitComponent.REGISTRY]: '/onboard/new',
  [BusinessUnitComponent.SEATING_CHART]: '/wedding/onboard/wedding-planning',
  [BusinessUnitComponent.STORE]: undefined,
  [BusinessUnitComponent.INSPIRATION]: undefined,
  [BusinessUnitComponent.MARKETPLACE]: undefined,
  [BusinessUnitComponent.HOLIDAY]: undefined,
  [BusinessUnitComponent.WEDDING_ALBUM]: undefined,
  [BusinessUnitComponent.COMMUNITY]: undefined,
  [BusinessUnitComponent.WEDDING_SHOWER_INVITATION]: undefined,
  [BusinessUnitComponent.BUDGET_TOOL]: '/wedding/onboard/budget',
};

/*
  If case returns true, allow the user to view the signup form
  If case returns false (most cases), send them to the corresponding onboarding flow
*/
const signupLinkCallback = (primaryLinkId?: PrimaryNavIdentifier): boolean => {
  let businessUnit = detectBusinessUnit();
  let businessUnitFeature = detectBusinessUnitFeature();
  const path = window.location.pathname;
  // Special rules for homepage/expert advice
  if (path === '/' || path.indexOf('/expert-advice') === 0) {
    window.location.href = '/wedding/onboard/wedding-planning';
    return false;
  }
  const isHoneymoons = window.location.pathname === '/honeymoons';
  if (isHoneymoons) {
    window.location.href = '/wedding/onboard/wedding-planning';
    return false;
  }

  if (primaryLinkId === NAV_DATA.PRIMARY.WEDDING_BOUTIQUE) {
    businessUnit = BusinessUnit.WEDDINGS;
    businessUnitFeature = BusinessUnitComponent.WEDDING_PLANNING;
  }

  if (primaryLinkId === NAV_DATA.PRIMARY.BUDGET) {
    businessUnit = BusinessUnit.BUDGET_TOOL;
    businessUnitFeature = BusinessUnitComponent.BUDGET_TOOL;
  }

  switch (businessUnit) {
    case BusinessUnit.MARKETPLACE:
    case BusinessUnit.INSPIRATION: {
      const marketplaceSkipOnboarding = getSessionStorage('marketplaceSkipOnboarding');
      if (!marketplaceSkipOnboarding) {
        window.location.href = '/inspiration/onboard';
        return false;
      }
      return true;
    }
    case BusinessUnit.STORE:
      trackOnboardingStarted(businessUnit, businessUnit, false);
      return true;
    case BusinessUnit.PHOTOBOOK:
      if (onDirectSignup() === null) {
        window.location.href = '/wedding-albums/select?album_type=premier&showDirectSignup=true';
      }
      return false;
    case BusinessUnit.WEDDING_REGISTRY:
      window.location.href = '/wedding/onboard/registry?flow_entry=GET_STARTED';
      return false;
    case BusinessUnit.WEDDINGS:
    case BusinessUnit.DIGITAL_SAVE_THE_DATE:
    case BusinessUnit.INVITATIONS: {
      const onboardUrl = mapComponentToOnboardFlow[businessUnitFeature];
      if (path === '/wedding-planning/paper') {
        // if coming from universal LP, don't add category attribution (:paperProduct param)
        window.location.href = '/wedding/onboard/wedding-paper';
      } else if (onboardUrl) {
        window.location.href = onboardUrl;
      } else {
        onDirectSignup();
      }
      return false;
    }
    case BusinessUnit.BUDGET_TOOL:
      window.location.href = '/wedding/onboard/budget';
      return false;
    default:
      window.location.href = '/wedding/onboard/wedding-planning';
      return false;
  }
};

export const isRegistry = (primaryLinkId?: PrimaryNavIdentifier): boolean => {
  if (typeof window !== 'undefined') {
    let businessUnit = detectBusinessUnit();
    const path = window.location.pathname;
    // Special rules for homepage/expert advice
    if (path === '/' || path.indexOf('/expert-advice') === 0) {
      return false;
    }
    const isHoneymoons = window.location.pathname === '/honeymoons';
    if (isHoneymoons) {
      return false;
    }

    if (primaryLinkId === NAV_DATA.PRIMARY.WEDDING_BOUTIQUE) {
      businessUnit = BusinessUnit.WEDDINGS;
    }

    return businessUnit === BusinessUnit.WEDDING_REGISTRY;
  }
  return false;
};

export default signupLinkCallback;
