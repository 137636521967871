export const getHomestoreHref = (zolaEnv?: string): string => {
  switch (zolaEnv) {
    case 'development':
      return 'http://127.0.0.1:9000';
    case 'staging':
      return 'https://qa-homestore.zola.com';
    case 'production':
    default:
      return 'https://homestore.zola.com';
  }
};
