import _omit from 'lodash/omit';

import { SecondaryNavItem } from '@zola-helpers/client/dist/es/constants/navConstants';

const normalizeNavNode = (
  instance: SecondaryNavItem = {} as SecondaryNavItem,
  index: number
): SecondaryNavItem => {
  const instanceWithOut = _omit(instance, [
    'text',
    'dropdownItems',
    'indentifier',
  ]) as SecondaryNavItem;
  const idExists = Boolean(instance.id || instance.identifier);

  return {
    ...instanceWithOut,
    ...(idExists && { id: instance.id || instance.identifier }),
    title: instance.title || instance.text || '',
    children: instance.children || instance.dropdownItems,
    position: index + 1, // position is 1-indexed.
  };
};

export const mapNavNodes = (tree: SecondaryNavItem[]): SecondaryNavItem[] => {
  const newTree: SecondaryNavItem[] = [];
  tree.forEach((node, i) => {
    const normalizedNode = normalizeNavNode(node, i);

    if (normalizedNode.children && normalizedNode.children.length) {
      normalizedNode.children = mapNavNodes(normalizedNode.children);
    } else {
      normalizedNode.children = [];
    }

    newTree.push(normalizedNode);
  });

  return newTree;
};
