import React, { useMemo, useRef } from 'react';

import {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  SecondaryNavItem,
  TertiaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';

import { getExternalLinkIcon, shouldOpenNewTab } from 'components/navV3/PostAuth/utils';
import { sanitizeAndTrackNavigationClicked } from 'util/trackNavigationClickedHelpersV2';
import useScrollActiveNavItemToView from 'components/navV3/PostAuthMobile/useScrollActiveNavItemToView';

import { ScrimContainer, LeftScrim, RightScrim } from 'components/navV3/sharedStyles.styles';
import { MobileSecondaryNavContainer, MobileSecondaryNavItem } from './MobileSecondaryNav.styles';

type MobileSecondaryNavProps = {
  primaryLinkId: PrimaryNavIdentifier | '';
  secondaryLinkId: SecondaryNavIdentifier | '';
  secondaryNavData: SecondaryNavItem[];
  tertiaryLinkId: TertiaryNavIdentifier | '';
  tertiaryNavData: SecondaryNavItem[];
};

export const MobileSecondaryNav = ({
  primaryLinkId,
  secondaryLinkId,
  secondaryNavData,
  tertiaryLinkId,
  tertiaryNavData,
}: MobileSecondaryNavProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  useScrollActiveNavItemToView(containerRef);

  // Need to show tertiary nav data on vendor pages for mweb pre-auth nav
  const showTertiaryNavData = useMemo(
    () => primaryLinkId === PrimaryNavIdentifier.VENDORS && tertiaryNavData,
    [primaryLinkId, tertiaryNavData]
  );
  const navDataToShow = useMemo(
    () => (showTertiaryNavData ? tertiaryNavData : secondaryNavData),
    [secondaryNavData, showTertiaryNavData, tertiaryNavData]
  );

  const getSecondaryNavItems = () =>
    navDataToShow.map((node, i) => {
      const active = (showTertiaryNavData ? tertiaryLinkId : secondaryLinkId) === node.id;
      return (
        <MobileSecondaryNavItem
          active={active}
          key={`mobile-secondary-nav-item-${i}`}
          noTextTransform
          noUnderline
          onClick={() =>
            sanitizeAndTrackNavigationClicked({
              firstNode: node,
              navigationType: 'CATEGORICAL',
            })
          }
          sizes="smaller"
          subtle={!active}
          target={shouldOpenNewTab(node.title as string) ? '_blank' : '_self'}
          {...(node.path ? { href: node.path } : { role: 'button' })}
        >
          {node.title}
          {getExternalLinkIcon(node.title as string)}
        </MobileSecondaryNavItem>
      );
    });

  return (
    <ScrimContainer>
      <MobileSecondaryNavContainer ref={containerRef}>
        {getSecondaryNavItems()}
      </MobileSecondaryNavContainer>
      <LeftScrim />
      <RightScrim />
    </ScrimContainer>
  );
};
