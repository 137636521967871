import _isEmpty from 'lodash/isEmpty';
import { getCookie } from '@zola-helpers/client/dist/es/util/storage';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

export const isLoggedIn = (userContext: UserContext | undefined) =>
  (typeof userContext?.is_guest === 'boolean' && !userContext.is_guest) ||
  (_isEmpty(userContext) && Boolean(getCookie('zolaLoggedIn')));

export const getDefaultLogoutDestination = (userContext: UserContext | undefined) =>
  (userContext?.business_unit_source as string | undefined) === 'MARKETPLACE' ||
  (userContext?.business_unit_source as string | undefined) === 'INSPIRATION'
    ? '/wedding-vendors'
    : '/';
