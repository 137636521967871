import { useState } from 'react';

import { LocationOrAllMarketsType } from '@zola-helpers/client/dist/es/marketplace/types';
import { getSearchedLocation } from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';

export const useCurrentVendorSearchedLocation = () => {
  const [searchedLocation, setSearchedLocation] = useState<LocationOrAllMarketsType>();

  const handleLocationChange = () => {
    setSearchedLocation(getSearchedLocation());
  };

  useEffectOnce(() => {
    handleLocationChange();

    window.addEventListener('SEARCHED_LOCATION_CHANGE', handleLocationChange);

    return (): void => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('SEARCHED_LOCATION_CHANGE', handleLocationChange);
      }
    };
  });

  return { searchedLocation, setSearchedLocation };
};
