import React from 'react';
import { Provider } from 'react-redux';
import { hydrate, render } from 'react-dom';
import { loadableReady } from '@loadable/component';

import store from './store';
import App from './App';

loadableReady(() => {
  try {
    const root = document.getElementById('root-nav');
    hydrate(
      <Provider store={store}>
        <App />
      </Provider>,
      root
    );
  } catch (e) {
    const root = document.getElementById('root-nav');
    render(
      <Provider store={store}>
        <App />
      </Provider>,
      root
    );
  }
}).catch((err) => {
  // eslint-disable-next-line no-console
  console.log(err);
});
