import React, { useEffect, useState } from 'react';

import loadable from '@loadable/component';
// import { useSelector } from 'react-redux';
// import RenderTest from './RenderTest/RenderTest';

import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';
import { isVendorStorefrontPage } from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import featureFlags from './util/featureFlags';

import NavContainer from './components/navV2/NavContainer';
import renderOrganizationJsonLdScript from './components/navV2/orgSchema';

const ModalRoot = loadable(() => import('./components/common/ModalRoot'), { ssr: false });
const ToastsContainer = loadable(() => import('./components/toastsContainer'), { ssr: false });
const FeatureToggle = loadable(() => import('./components/FeatureToggle'), { ssr: false });
const SideDrawerRoot = loadable(() => import('./components/common/SideDrawerRoot'), { ssr: false });

function App() {
  const [showFeatureToggle, setShowFeatureToggle] = useState(false);
  // const state = useSelector((appState) => appState);

  useEffect(() => {
    setShowFeatureToggle(featureFlags.get('featureToggle'));
  }, []);

  const [showStructuredData, setShowStructuredData] = useState(false);
  useEffectOnce(() => {
    setShowStructuredData(!isVendorStorefrontPage());
  });

  return (
    <React.Fragment>
      {/* <RenderTest /> */}
      <a href="/webaccessibility" tabIndex={0} className="accessibility-link screen-only">
        Web Accessibility
      </a>
      <NavContainer />
      <ModalRoot />
      <SideDrawerRoot />
      <ToastsContainer />
      {showFeatureToggle && <FeatureToggle />}
      {showStructuredData && renderOrganizationJsonLdScript()}
    </React.Fragment>
  );
}

export default App;
