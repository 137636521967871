import styled from '@emotion/styled';
import { css } from '@emotion/react';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';

export const Container = styled.div`
  padding: ${SPACING.S24} 0;
`;

export const UnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.S24};
  padding-left: 0;
`;

export const liBaseStyles = css`
  padding-inline-start: 0;
  margin: 0;
  padding: 0;
  display: block;
`;

export const StyledLinkV2 = styled(LinkV2)`
  display: flex;
  align-items: center;
  gap: ${SPACING.SM};
`;
