import styled from '@emotion/styled';

import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

export const Container = styled.div`
  margin: ${SPACING.S16} 0;
  display: flex;
  width: 100%;
  align-items: center;
  & > :first-of-type {
    margin-right: 8px;
  }
  button {
    flex: 1;
  }
`;
