import ApiService from '@zola-helpers/client/dist/es/http/api';
import { VendorTypeEnum } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import {
  SearchableVendorTaxonomyKey,
  VendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { UserContext } from '@zola-helpers/client/dist/types/@types';

import {
  BasePaginationViewListInquiryView,
  TaxonomyNodeView,
  UserPromptView,
} from '@zola/svc-marketplace-ts-types';
import { TriggerLocation } from './types';

export const snoozeModal = (): void => {
  ApiService.put(
    '/web-marketplace-api/v1/user-prompt/snooze?prompt_key=INQUIRY_MAINTENANCE',
    {}
  ).catch(() => null);
};

export const getUserPrompt = (promptKey: string): Promise<UserPromptView> =>
  ApiService.get(`/web-marketplace-api/v1/user-prompt/prompt?prompt_key=${promptKey}`);

export const getConnectedInquiries = (
  vendorType: VendorTypeEnum
): Promise<BasePaginationViewListInquiryView> => {
  return ApiService.post(`/web-marketplace-api/v1/inquiry/account/unmappedlist`, {
    statuses: ['CONNECTED'],
    vendorType,
  });
};

export const getUnbookedVendorKeys = (
  vendorTaxonomyKey: VendorTaxonomyKey | undefined
): Promise<SearchableVendorTaxonomyKey[]> => {
  return ApiService.get<TaxonomyNodeView[]>(
    `/web-marketplace-api/v1/account/unbooked-taxonomy-nodes`
  )
    .then((categories) => {
      const unbookedCats = categories.filter((c) => c.key !== vendorTaxonomyKey);
      return unbookedCats.map((u) => u.key as SearchableVendorTaxonomyKey);
    })
    .catch(() => []);
};

export const getTriggerLocation = (): TriggerLocation => {
  if (window.location.href.includes('/wedding/manage/guests/all')) {
    return 'GUESTLIST';
  }
  if (window.location.href.includes('/wedding/manage/website/manage')) {
    return 'WEBSITE';
  }

  return 'UNATTRIBUTED';
};

export const userEligibleForModal = (user: UserContext): boolean => {
  const isLoggedIn = user && !user.is_guest;
  const triggerLocation = getTriggerLocation();
  if (isLoggedIn) {
    if (triggerLocation === 'GUESTLIST' && user.has_guest_list) {
      return true;
    }
    if (triggerLocation === 'WEBSITE' && user.has_website) {
      return true;
    }
  }

  return false;
};

export const inquiryWord = (count: number): string => {
  if (count === 1) {
    return 'inquiry';
  }
  return 'inquiries';
};
