import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import { receiveUser } from 'actions/UserActions';
import { getUserContext } from 'selectors/user';

import NAV_DATA, {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  TertiaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { isLoggedIn } from 'util/authHelpers';
import { mapNavNodes } from 'util/models/NavNode';
import useInitWebZolaNavData from 'components/navV3/PostAuthMobile/useInitWebZolaNavData';
import useSetWebZolaNavStyles from 'components/navV3/PostAuth/useSetWebZolaNavStyles';

const {
  EVENTS: { NAV_LOADED, INIT_NAV_DATA },
} = NAV_DATA;

// TODO: Compare this with useNavData.ts and merge if possible
export const useMobileNavDataListeners = () => {
  const dispatch = useDispatch();
  const userContext = useSelector(getUserContext);
  const [primaryLinkId, setPrimaryLinkId] = useState<PrimaryNavIdentifier | ''>('');
  const [secondaryLinkId, setSecondaryLinkId] = useState<SecondaryNavIdentifier | ''>('');
  const [tertiaryLinkId, setTertiaryLinkId] = useState<TertiaryNavIdentifier | ''>('');
  const [secondaryNavData, setSecondaryNavData] = useState<SecondaryNavItem[]>([]);
  const [tertiaryNavData, setTertiaryNavData] = useState<SecondaryNavItem[]>([]);

  const handleInitData = useCallback(
    (event) => {
      const {
        detail: {
          activePrimaryLink,
          activeSecondaryLink,
          activeTertiaryLink,
          secondaryData,
          tertiaryData,
          userContext: passedUserContext,
        },
      } = event;

      // only set if passed, otherwise calls w/o userContext will overwrite
      if (!_isEmpty(passedUserContext)) {
        dispatch(receiveUser(passedUserContext));
      }

      if (typeof activePrimaryLink !== 'undefined') {
        setPrimaryLinkId(activePrimaryLink);
      }
      if (typeof activeSecondaryLink !== 'undefined') {
        setSecondaryLinkId(activeSecondaryLink);
      }
      if (typeof activeTertiaryLink !== 'undefined') {
        setTertiaryLinkId(activeTertiaryLink);
      }
      if (secondaryData) {
        setSecondaryNavData(mapNavNodes(secondaryData));
      }
      if (tertiaryData) {
        setTertiaryNavData(mapNavNodes(tertiaryData));
      }
    },
    [dispatch]
  );
  useEffect(() => {
    window.addEventListener(INIT_NAV_DATA, handleInitData);
    window.dispatchEvent(new CustomEvent(NAV_LOADED));

    return () => {
      window.removeEventListener(INIT_NAV_DATA, handleInitData);
    };
  }, [handleInitData]);

  useEffect(() => {
    // Fallback incase the Nav Loaded event initially fires before the host app is ready
    const onHostAppReady = () => {
      window.dispatchEvent(new CustomEvent(NAV_LOADED));
    };
    window.addEventListener('HOST_APP_READY', onHostAppReady);
    return () => {
      window.removeEventListener('HOST_APP_READY', onHostAppReady);
    };
  });

  useInitWebZolaNavData(userContext);

  useSetWebZolaNavStyles({
    primaryLinkId,
    secondaryNavData,
    tertiaryNavData,
    isPostAuth: isLoggedIn(userContext),
  });

  return { primaryLinkId, secondaryLinkId, tertiaryLinkId, secondaryNavData, tertiaryNavData };
};
