import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { WUniversalSearchSuggestionResultsView } from '@zola/svc-web-api-ts-client';
import {
  UniversalSearchSuggestionCategories,
  UniversalSearchProductStore,
  UniversalSearchCategorySuggestionsView,
} from 'components/search/types';

export type UniversalSearchOptions = {
  /* defaults to all on BE */
  enabled_search_categories?: UniversalSearchSuggestionCategories[];
  /* defaults to WEDDING_REGISTRY on BE */
  product_store?: UniversalSearchProductStore;
  /* defaults to `false` on BE */
  highlighted?: boolean;
};

type UniversalSearchSuggestionResultsView = Omit<
  WUniversalSearchSuggestionResultsView,
  'categories'
> & { categories: UniversalSearchCategorySuggestionsView[] };

export const fetchUniversalSuggestions = (
  query: string,
  options?: UniversalSearchOptions
): Promise<UniversalSearchSuggestionResultsView> => {
  const { highlighted = false, ...restOptions } = options || {};
  const request = {
    query,
    highlighted,
    ...restOptions,
  };
  return ApiService.post(`/website-nav/web-api/v1/universalSearch/suggest`, request);
};
