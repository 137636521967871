export const getBabyHref = (zolaEnv?: string): string => {
  switch (zolaEnv) {
    case 'development':
      return 'http://dev-baby.zola.com';
    case 'staging':
      return 'https://qa-baby.zola.com';
    case 'production':
    default:
      return 'https://baby.zola.com';
  }
};
