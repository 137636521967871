/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import FocusTrap from 'focus-trap-react';
import { BellIcon as BellIconV3 } from '@zola/zola-ui/src/components/SvgIconsV3/Bell';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import NotificationMenu from 'components/common/notification/NotificationMenu';
import NotificationPortal from 'components/common/notification/NotificationPortal';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import type { WZolaActivity } from '@zola/svc-web-api-ts-client';
import {
  fetchNotificationUnseenCount,
  fetchNotificationData,
  updateNotificationRead,
} from '@zola-helpers/client/dist/es/notification/notification';

const Notification: React.FC<{
  userId: string;
  className?: string;
  isMobileNavV3?: boolean;
  isPostAuthNav3Enabled?: boolean;
  navigationLevelPosition?: number;
}> = ({ userId, className, isMobileNavV3, isPostAuthNav3Enabled, navigationLevelPosition }) => {
  const [unseen, setUnseen] = useState<number | undefined>();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [notification, setNotification] = useState<Array<WZolaActivity> | undefined>(undefined);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const bellRef = useRef<HTMLButtonElement | null>(null);

  function disableScroll(disable: boolean) {
    const HTMLDOM = document.querySelector('html');
    if (HTMLDOM) {
      HTMLDOM.style.cssText = disable ? 'height: 100%;overflow: hidden;' : '';
    }
  }

  // helper function which close the modal and re-enable scrolling
  function toggleMenu(openMenu: boolean) {
    setShowMenu(openMenu);
    disableScroll(openMenu);
  }

  useEffect(() => {
    const hideMenu = (key: KeyboardEvent) => {
      if (key.keyCode === 27) {
        toggleMenu(false);
      }
    };
    document.addEventListener('keydown', hideMenu);
    return () => {
      document.removeEventListener('keydown', hideMenu);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userId) {
      fetchNotificationUnseenCount()
        .then((data) => {
          setUnseen(data.unseen);
        })
        .catch(() => {
          console.warn('Unable to fetch notification unseen'); // eslint-disable-line no-console
        });
    }
  }, [userId]);

  function handleClickOutside(e: MouseEvent) {
    if (
      !menuRef?.current?.contains(e.target as Node) &&
      !bellRef?.current?.contains(e.target as Node)
    ) {
      toggleMenu(false);
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClick = () => {
    trackNavigationClicked({
      business_category: 'UNATTRIBUTED',
      business_unit: 'UNATTRIBUTED',
      navigation_level_1: 'NOTIFICATIONS',
      navigation_level_1_position: navigationLevelPosition || 1,
      navigation_type: 'UTILITY',
    });
    if (!showMenu) {
      fetchNotificationData()
        .then((response) => {
          setNotification(response.activities);
          setUnseen(undefined);
          toggleMenu(true);
        })
        .catch(() => {
          console.warn('Unable to fetch notification activities'); // eslint-disable-line no-console
        });
    } else {
      setUnseen(undefined);
      toggleMenu(false);
    }
  };

  const markActivityRead = (groupId: string) => {
    updateNotificationRead(groupId).catch(() => {
      console.warn('Unable to mark notification activities as read'); // eslint-disable-line no-console
    });
  };

  return (
    <LinkV2
      innerRef={bellRef}
      id="notification-icon"
      className={cx('icon-link nav-util__bell top-nav__bell', className, {
        'icon-link--selected': showMenu && isPostAuthNav3Enabled,
      })}
      aria-label="Your Notifications"
      onClick={handleClick}
      role="button"
    >
      <BellIconV3 title="Notifications" height={20} width={20} />
      {unseen ? <span className="badge">{unseen}</span> : null}
      {showMenu ? (
        <NotificationPortal>
          <FocusTrap
            active={showMenu && notification && notification.length > 0}
            focusTrapOptions={{ clickOutsideDeactivates: true }}
          >
            <div>
              <NotificationMenu
                menuRef={menuRef}
                activities={notification}
                handleMenuItemClick={markActivityRead}
                isMobileNavV3={isMobileNavV3}
                isPostAuthNav3Enabled={isPostAuthNav3Enabled}
              />
            </div>
          </FocusTrap>
        </NotificationPortal>
      ) : null}
    </LinkV2>
  );
};

export default Notification;
