import React from 'react';
import CreateIconComponent, {
  CreateIconComponentProps,
} from '@zola/zola-ui/src/components/SvgIcons/CreateIconComponent';

const SearchIcon = ({
  title = 'Search',
  viewBox = '0 0 24 24',
  ...props
}: CreateIconComponentProps): JSX.Element => (
  <CreateIconComponent title={title} viewBox={viewBox} {...props} fill="none">
    {/* prettier-ignore */}
    <circle cx="10" cy="10" r="7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    {/* prettier-ignore */}
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m21 21-6-6" />
  </CreateIconComponent>
);

export default SearchIcon;
