import React from 'react';
import CreateIconComponent, {
  CreateIconComponentProps,
} from '@zola/zola-ui/src/components/SvgIcons/CreateIconComponent';

const CloseIcon = ({
  title = 'X',
  viewBox = '0 0 24 24',
  ...props
}: CreateIconComponentProps): JSX.Element => (
  <CreateIconComponent title={title} viewBox={viewBox} {...props} fill="none">
    {/* prettier-ignore */}
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 6 6 18M6 6l12 12" />
  </CreateIconComponent>
);

export default CloseIcon;
