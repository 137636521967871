import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
// Components
import { ExternalLinkIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ExternalLink';

// Constants
import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import { SPACING } from '@zola/zola-ui/src/styles/emotion';

// Types
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

// Utils
import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';

export const isPostAuthNav3Desktop = (userContext?: UserContext | null): boolean => {
  if (!userContext || typeof window === 'undefined') {
    return false;
  }
  return !userContext.is_guest && isDesktop();
};

export const isPostAuthNav3Mobile = (userContext?: UserContext | null): boolean => {
  if (!userContext || typeof window === 'undefined') {
    return false;
  }
  return !userContext.is_guest && !isDesktop() && !userContext.is_baby_registry_only;
};

export const shouldOpenNewTab = (linkName: string): boolean => {
  return linkName === 'Zola Home' || linkName === 'Hotel blocks' || linkName === 'Zola Baby';
};

export const getExternalLinkIcon = (linkName: string): ReactNode => {
  return shouldOpenNewTab(linkName) ? (
    <ExternalLinkIcon
      css={css`
        margin-left: ${SPACING.XS};
      `}
      title="Link"
      height={16}
      width={16}
    />
  ) : null;
};

type PageDetail = {
  title: string;
  path: string;
  titleComponent?: 'h1';
};

export const getPageDetail = (
  activeLinkId: '' | PrimaryNavIdentifier | undefined,
  activeSecondaryLink: string,
  userContext?: UserContext
): PageDetail => {
  const {
    has_registry,
    has_website,
    has_invitation_account,
    invitation_account,
    slug,
    wedding_account,
  } = userContext || {};

  const budgetDetail = { title: 'Budget', path: '/wedding/manage/budget' };
  const registryDetail = {
    title: 'Registry',
    path: has_registry ? `/registry/${slug}/edit` : '/onboard/new',
  };

  if (
    !activeLinkId &&
    typeof window !== 'undefined' &&
    window.location.pathname.includes('budget')
  ) {
    return budgetDetail;
  }

  if (activeSecondaryLink === 'real-weddings') {
    return { title: 'Inspiration', path: '/inspiration' };
  }

  // Special case for the wedding boutique collections, which is a boutique page but we want displayed as registry
  if (
    typeof window !== 'undefined' &&
    window.location.pathname.includes('shop/gift-collections/wedding-shop-collections')
  ) {
    return registryDetail;
  }

  switch (activeLinkId) {
    case PrimaryNavIdentifier.ACCOUNT: {
      return { title: 'Account settings', path: '/account/settings/info', titleComponent: 'h1' };
    }
    case PrimaryNavIdentifier.ADVICE: {
      return { title: 'Expert advice', path: '/expert-advice' };
    }
    case PrimaryNavIdentifier.ADVISOR: {
      return { title: 'Team Z advisors', path: '/advisor' };
    }
    case PrimaryNavIdentifier.ALBUMS: {
      return {
        title: 'Albums',
        path: invitation_account?.photobooks_onboarding_completed
          ? '/wedding-albums/drafts'
          : '/wedding-albums/shop',
      };
    }
    case PrimaryNavIdentifier.APP: {
      return { title: 'Mobile app', path: '/wedding-planning/app' };
    }
    case PrimaryNavIdentifier.BUDGET: {
      return budgetDetail;
    }
    case PrimaryNavIdentifier.CAREERS: {
      return { title: 'Careers', path: '/about/team' };
    }
    case PrimaryNavIdentifier.CHECKLIST: {
      return { title: 'Checklist', path: '/wedding-planning/checklist' };
    }
    case PrimaryNavIdentifier.COMMUNITY: {
      return { title: 'Community', path: '/community' };
    }
    case PrimaryNavIdentifier.FAVORITES: {
      return { title: 'Favorites', path: '/favorites/home' };
    }
    case PrimaryNavIdentifier.GUEST_LIST: {
      return { title: 'Guest list and RSVPs', path: '/wedding/manage/guests/all' };
    }
    case PrimaryNavIdentifier.HONEYMOONS: {
      return { title: 'Honeymoons', path: '/wedding-planning/honeymoons' };
    }
    case PrimaryNavIdentifier.HELP: {
      return { title: 'Help', path: '/contact-us' };
    }
    case PrimaryNavIdentifier.OUR_STORY: {
      return { title: 'Our story', path: '/about/index' };
    }
    case PrimaryNavIdentifier.PAPER: {
      return {
        title: 'Invites and paper',
        path: has_invitation_account
          ? '/wedding-planning/projects/drafts'
          : '/wedding-planning/universal',
      };
    }
    case PrimaryNavIdentifier.PRESS: {
      return { title: 'Press', path: '/about/press' };
    }
    case PrimaryNavIdentifier.REGISTRY: {
      return registryDetail;
    }
    case PrimaryNavIdentifier.REFER_A_FRIEND: {
      return { title: 'Refer a friend', path: '/wedding-registry/invite' };
    }
    case PrimaryNavIdentifier.RETURN_POLICY: {
      return { title: 'Return policy', path: '/returns' };
    }
    case PrimaryNavIdentifier.REVIEWS: {
      return { title: 'Reviews', path: '/wedding-registry/couple-reviews' };
    }
    case PrimaryNavIdentifier.SEATING: {
      return {
        title: wedding_account?.seating_chart_v1 ? 'Seating chart' : 'Guest list and RSVPs',
        path: wedding_account?.seating_chart_v1
          ? '/wedding/manage/seating'
          : '/wedding/manage/guests/all',
      };
    }
    case PrimaryNavIdentifier.TERMS_OF_USE: {
      return { title: 'Terms of use', path: '/terms' };
    }
    case PrimaryNavIdentifier.TRACK_ORDER: {
      return { title: 'Track order', path: '/order-status/lookup' };
    }
    case PrimaryNavIdentifier.VENDORS: {
      return { title: 'Venue and vendors', path: '/wedding-vendors' };
    }
    case PrimaryNavIdentifier.WEBSITE: {
      return {
        title: 'Website',
        path: has_website ? '/wedding/manage/website/manage' : '/wedding-planning/website/designs',
      };
    }
    case PrimaryNavIdentifier.WEB_ACCESSIBILITY: {
      return { title: 'Web accessibility', path: '/webaccessibility' };
    }
    case PrimaryNavIdentifier.WEDDING_BOUTIQUE: {
      return { title: 'Boutique', path: '/shop/category/wedding-shop' };
    }
    case PrimaryNavIdentifier.YOUR_WEDDING: {
      return { title: 'Your wedding', path: '/wedding/manage' };
    }
    default:
      return { title: '', path: '/' };
  }
};
