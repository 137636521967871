import { Dispatch } from 'redux';
import type { WStoreNavView, WWeddingShopNavView } from '@zola/svc-web-api-ts-client';
import * as ActionType from './types/NavActionTypes';
import * as navClient from '../client/v1/nav';

// UNIVERSAL TABS
export function setActiveUniversalTab(tab: string): ActionType.NavActionTypes {
  return {
    type: ActionType.SET_ACTIVE_UNIVERSAL_TAB,
    payload: tab,
  };
}

// CATEGORIES IN DROPDOWN
export function requestCategories(): ActionType.NavActionTypes {
  return {
    type: ActionType.REQUEST_CATEGORY,
  };
}

export function receiveCategories(response: WStoreNavView): ActionType.NavActionTypes {
  return {
    type: ActionType.RECEIVE_CATEGORY,
    payload: response,
  };
}

export function getCategories(businessUnit = 'WEDDING_REGISTRY') {
  return (dispatch: Dispatch<ActionType.NavActionTypes>): Promise<WStoreNavView> => {
    dispatch(requestCategories());
    return navClient.getCategories(businessUnit).then((json) => {
      dispatch(receiveCategories(json));
      return json;
    });
  };
}

// WEDDING SHOP CATEGORIES

export function requestWeddingShopCategories(): ActionType.NavActionTypes {
  return {
    type: ActionType.REQUEST_WEDDING_SHOP_CATEGORY,
  };
}

export function receiveWeddingShopCategories(
  response: WWeddingShopNavView
): ActionType.NavActionTypes {
  return {
    type: ActionType.RECEIVE_WEDDING_SHOP_CATEGORY,
    payload: response,
  };
}

export function getWeddingShopCategories() {
  return (dispatch: Dispatch<ActionType.NavActionTypes>): Promise<WWeddingShopNavView> => {
    dispatch(requestWeddingShopCategories());
    return navClient.getWeddingShopCategories().then((json) => {
      dispatch(receiveWeddingShopCategories(json));
      return json;
    });
  };
}

// manage the guest request request response :joy:
export function manageGuestRequests(json: number): ActionType.NavActionTypes {
  return {
    type: ActionType.GET_GUEST_REQUESTS,
    payload: json,
  };
}

export function getGuestRequests() {
  return (dispatch: Dispatch<ActionType.NavActionTypes>): Promise<ActionType.NavActionTypes> => {
    return navClient
      .getRsvpRequestCountByWeddingAccountId()
      .then((json) => dispatch(manageGuestRequests(json)));
  };
}
