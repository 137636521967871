import styled from '@emotion/styled';
import { css } from '@emotion/react';
import COLORS, { COLORS_BUTTONS } from '@zola/zola-ui/src/styles/emotion/colors3';
import utils from '@zola/zola-ui/src/styles/emotion/utils';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

export const GladlyButton = styled.button<{ mobileBottom?: string }>`
  position: fixed;
  right: 20px;
  bottom: 20px;

  ${MEDIA_QUERY_V2.MOBILE} {
    bottom: ${(props) => (props.mobileBottom ? props.mobileBottom : '20px')};
  }

  width: 56px;
  height: 56px;
  background-color: ${COLORS_BUTTONS.BAY_100};
  color: ${COLORS_BUTTONS.CLOUD_100};
  box-shadow: none;
  ${utils.circle};
  border: none;
  align-items: center;
  justify-content: center;

  :hover,
  :active,
  :focus {
    background-color: ${COLORS_BUTTONS.BAY_085};
  }

  display: none;
  &.gladly-show,
  &.gladly-unread,
  .gladlyChat_open & {
    display: flex;
  }
`;

const unreadIndicatorSize = '8px';
export const UnreadDot = styled.div`
  ${utils.circle};
  width: ${unreadIndicatorSize};
  height: ${unreadIndicatorSize};
  background-color: ${COLORS_BUTTONS.ROSE_100};

  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
  ${GladlyButton}.gladly-unread & {
    display: inline-block;
  }
  border: 1px solid ${COLORS_BUTTONS.WHITE_100};
`;

export const GladlyStyles = css`
  #gladlyChat_container {
    a {
      color: ${COLORS.BLACK_100} !important;

      &:hover,
      &:active,
      &:focus {
        color: ${COLORS.BLACK_075} !important;
      }
    }
  }
`;
