import { useState, useMemo, useCallback, useEffect } from 'react';
import _throttle from 'lodash/throttle';

export default function useScrollPosition(emitDirectionEvent?: boolean) {
  const [scroll, setScroll] = useState({
    x: (typeof window !== 'undefined' && window?.scrollX) || 0,
    y: (typeof window !== 'undefined' && window?.scrollY) || 0,
    direction: '',
  });

  const listener = useCallback(() => {
    const newDirection = scroll.y < window.scrollY && scroll.y >= 0 ? 'down' : 'up';
    const prevDirection = scroll.direction;
    setScroll((prev) => ({
      x: window.scrollX,
      y: window.scrollY,
      direction: prev.y < window.scrollY && prev.y >= 0 ? 'down' : 'up',
    }));
    if (emitDirectionEvent && newDirection !== prevDirection) {
      window.dispatchEvent(
        new CustomEvent('NAV_DIRECTION', { detail: { direction: newDirection } })
      );
    }
  }, [scroll, emitDirectionEvent]);

  const throttleFn = useMemo(() => _throttle(listener, 300), [listener]);

  useEffect(() => {
    window.addEventListener('scroll', throttleFn);
    // cleanup function occurs on unmount
    return () => window.removeEventListener('scroll', throttleFn);
  }, [throttleFn]); // Run `useEffect` only once on mount

  return scroll;
}
