import React, { LegacyRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import DefaultNotificationPanel from 'components/common/notification/Panels/DefaultNotificationPanel';
import NotificationListPanel from 'components/common/notification/Panels/NotificationListPanel';
import type { RootState } from 'reducers/index';
import { getUserContext } from 'selectors/user';
import { isPostAuthNav3Mobile } from 'components/navV3/PostAuth/utils';
import {
  CtaType,
  trackCtaClicked,
} from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';
import type { WZolaActivity } from '@zola/svc-web-api-ts-client';
import styles from './Notification.module.less';

interface NotificationMenuProps {
  activities?: WZolaActivity[];
  handleMenuItemClick: (groupId: string) => void;
  menuRef: LegacyRef<HTMLDivElement> | undefined | null;
  isMobileNavV3?: boolean;
  isPostAuthNav3Enabled?: boolean;
}

const NotificationMenu: React.FC<NotificationMenuProps> = ({
  activities,
  handleMenuItemClick,
  menuRef,
  isMobileNavV3 = false,
  isPostAuthNav3Enabled = false,
}) => {
  const userContext = useSelector((state: RootState) => getUserContext(state));
  const isPostAuthNav3MobileEnabled = isPostAuthNav3Enabled && isPostAuthNav3Mobile(userContext);

  const [arrowRightPos, setArrowRightPos] = useState<number>(0);
  const rootNavDOM = document.getElementById('root-nav') as Element;
  const bellDOM = document.getElementById('notification-icon') as Element;
  // only available on desktop
  const primaryNavDOM = document.getElementById('primary-nav');

  // calculate the height of the notification menu. We need to account for the height of primary nav
  // for desktop
  const primaryNavHeight = primaryNavDOM ? primaryNavDOM.getBoundingClientRect().height : 0;

  const menuHeight = rootNavDOM.getBoundingClientRect().height - primaryNavHeight + 20;

  const menuStyle = {
    ...(isPostAuthNav3MobileEnabled && { right: '12px' }),
    height: `calc(100% - ${rootNavDOM.getBoundingClientRect().height + 10}px)`,
  };

  // calculate the height of the backdrop. We need to account for the height of primary nav
  // for desktop
  const backdropOffset = rootNavDOM.getBoundingClientRect().height - primaryNavHeight;

  const arrowPosition = window.innerWidth - arrowRightPos - (isMobileNavV3 ? 28 : 35); // additional for dealing with padding on the icon
  const arrowStyle = {
    top: menuHeight - 15,
    right: arrowPosition,
  };

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      if (bellDOM) {
        setArrowRightPos(bellDOM.getBoundingClientRect().x);
      }
    });
    const overlayRootDOM = document.querySelector('body');
    ro.observe(overlayRootDOM as Element);
    return () => {
      ro.unobserve(overlayRootDOM as Element);
    };
  }, [bellDOM]);

  const handleLinkClick = () => {
    trackCtaClicked({
      business_unit: 'CORE',
      cta_type: CtaType.LINK,
      group_id: 'NOTIFICATION_FEED',
      cta_id: 'view-all-notifications',
      position: 1,
    });
    window.location.href = '/notifications';
  };

  return (
    <>
      <div
        id="notification-menu"
        className={cx(styles.menu, { [styles['menu--postAuthNav3']]: isPostAuthNav3Enabled })}
        style={menuStyle}
        ref={menuRef}
      >
        {isPostAuthNav3Enabled ? null : <div className={styles.arrow} style={arrowStyle} />}
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>Notifications</h1>
          {activities && activities.length > 0 && (
            <LinkV2
              {...(isPostAuthNav3Enabled ? {} : { arrow: true })}
              href="/notifications"
              className={styles.link}
              onClick={handleLinkClick}
              aria-label="View all notifications"
            >
              View All
            </LinkV2>
          )}
        </div>
        {activities && activities.length > 0 ? (
          <NotificationListPanel
            activities={activities}
            handleMenuItemClick={handleMenuItemClick}
            isPostAuthNav3Enabled={isPostAuthNav3Enabled}
          />
        ) : (
          <DefaultNotificationPanel />
        )}
      </div>
      {isPostAuthNav3Enabled ? null : (
        <div
          className={styles.backdrop}
          style={{
            height: `calc(100% - ${backdropOffset}px)`,
          }}
        />
      )}
    </>
  );
};

export default NotificationMenu;
