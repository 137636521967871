import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/types/constants/navConstants';

const PAPER_FAVORITES = '/favorites/paper' as const;
const VENDOR_FAVORITES = '/favorites/vendors' as const;
const FAVORITES_HOME = '/favorites/home' as const;

export const getFavoritesLink = (primaryLinkId: PrimaryNavIdentifier | '') => {
  if (primaryLinkId === NAV_DATA.PRIMARY.PAPER) {
    return PAPER_FAVORITES;
  }
  if (primaryLinkId === NAV_DATA.PRIMARY.VENDORS) {
    return VENDOR_FAVORITES;
  }
  return FAVORITES_HOME;
};
