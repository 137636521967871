import _forEach from 'lodash/forEach';
import { WEB_NAV_ID } from './constants/documentConstants';

type ElementType = Element | HTMLElement | null;

function addClassName(el: ElementType, className: string) {
  const updatedEl = el;
  if (updatedEl) {
    updatedEl.className = `${updatedEl.className} ${className}`;
  }
}

function resetClassName(el: ElementType, className: string) {
  const updatedEl = el;
  if (updatedEl) {
    const originalClassName = updatedEl.className.replace(className, '');
    updatedEl.className = originalClassName;
  }
}

function addClassNameToElements(className: string, elements: ElementType[]) {
  _forEach(elements, (el) => {
    addClassName(el, className);
  });
}

function removeClassNameFromElements(className: string, elements: ElementType[]) {
  _forEach(elements, (el) => resetClassName(el, className));
}

export function toggleDocumentOverlayClass(shouldAppend: boolean, className: string) {
  // A very trimmed down version of the menu function in () => toggleMenu()
  // in case another app needs to know that mobile search menu is currently active
  // TODO: Move this to a helper and make it globally available
  // Could potentially use this functionality for any modal/drawer/pop-up
  const HTMLTag = document.documentElement;
  const webNavEl = document.getElementById(WEB_NAV_ID);
  const bodyEl = document.body;
  if (shouldAppend) {
    HTMLTag.style.overflow = 'hidden';
    if (webNavEl) webNavEl.classList.add(className);
    bodyEl.classList.add(className);
  } else {
    HTMLTag.style.overflow = '';
    if (webNavEl) webNavEl.classList.remove(className);
    bodyEl.classList.remove(className);
  }
}

export function toggleClassNameOnElements(
  shouldAppend: boolean,
  className: string,
  elements: ElementType[]
) {
  if (shouldAppend) {
    addClassNameToElements(className, elements);
  } else {
    removeClassNameFromElements(className, elements);
  }
}
