import type { UniversalSearchSuggestionSelected } from '@zola/tracking-contracts/src/events';
import { trackUniversalSearchSuggestionSelected } from '@zola/tracking-contracts/src/tracking';

export type TrackUniversalSearchSuggestionSelected = Omit<
  UniversalSearchSuggestionSelected,
  'business_category' | 'business_unit'
>;

const trackUniversalSearchSuggestionSelection = (
  payload: TrackUniversalSearchSuggestionSelected
): void => {
  trackUniversalSearchSuggestionSelected({
    ...payload,
    business_category: 'UNATTRIBUTED',
    business_unit: 'UNATTRIBUTED',
  });
};

export default trackUniversalSearchSuggestionSelection;
