import styled from '@emotion/styled';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

export const ScrimContainer = styled.div`
  position: relative;
`;

const scrimStyles = `
  position: absolute;
  top: 1px;
  height: calc(100% - 1px);
  width: ${SPACING.MD};
  pointer-events: none;
`;

export const LeftScrim = styled.div`
  ${scrimStyles}
  background-image: linear-gradient(to left, transparent, ${COLORS.WHITE_100});
  left: 0;
`;

export const RightScrim = styled.div`
  ${scrimStyles}
  background-image: linear-gradient(to right, transparent, ${COLORS.WHITE_100});
  right: 0;
`;
