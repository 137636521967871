import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import utils from '@zola/zola-ui/src/styles/emotion/utils';

export const MobileSecondaryNavContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 ${SPACING.SM};
  overflow-x: scroll;
  border-top: 1px solid ${COLORS.BLACK_030};
  ${utils.concealScrollbars}
  ${MEDIA_QUERY.TABLET} {
    padding: 0 28px;
  }
`;

export const MobileSecondaryNavItem = styled(LinkV2)<{ active: boolean }>`
  padding: ${SPACING.XS} ${SPACING.SM};
  white-space: nowrap;
  &:hover {
    text-decoration: none !important;
  }
  ${({ active }) =>
    active
      ? css`
          background-color: ${COLORS.BLACK_005};
          border-radius: ${SPACING.XS};
        `
      : null}
`;
