import { RefObject, useEffect } from 'react';

/**
 * Scrolls active nav item into view
 */
const useScrollActiveNavItemToView = (containerRef: RefObject<HTMLDivElement>): void => {
  useEffect(() => {
    if (!containerRef) return;
    const container = containerRef.current;
    const activeItem = container?.querySelector('[class*="active"]') as HTMLAnchorElement;
    if (container && activeItem) {
      const containerWidth = container.clientWidth;
      const activeItemWidth = activeItem.clientWidth;
      const activeItemOffsetLeft = activeItem.offsetLeft;
      const scrollLeft = activeItemOffsetLeft - containerWidth / 2 + activeItemWidth / 2;
      container.scrollLeft = scrollLeft;
    }
  }, [containerRef]);
};

export default useScrollActiveNavItemToView;
