import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import type { UniversalSearchSuggestionSelected } from '@zola/tracking-contracts/src/events';

import { UniversalSearchSuggestionCategories } from '../types';

const BASE_PRIORITY_PRE_AUTH: UniversalSearchSuggestionCategories[] = [
  'FEATURE',
  'COUPLE',
  'SHOP',
  'PAPER',
  'MARKETPLACE',
];

const BASE_PRIORITY_POST_AUTH: UniversalSearchSuggestionCategories[] = [
  'FEATURE',
  'SHOP',
  'PAPER',
  'MARKETPLACE',
  'COUPLE',
];

const CUSTOM_PRIORITY_MARKETPLACE: UniversalSearchSuggestionCategories[] = [
  'FEATURE',
  'MARKETPLACE',
  'SHOP',
  'PAPER',
  'COUPLE',
];

const CUSTOM_PRIORITY_PAPER: UniversalSearchSuggestionCategories[] = [
  'FEATURE',
  'PAPER',
  'SHOP',
  'MARKETPLACE',
  'COUPLE',
];

const getCategoryPriority = (
  isGuest: boolean | undefined,
  customPriority?: PrimaryNavIdentifier | ''
): [
  UniversalSearchSuggestionCategories[],
  UniversalSearchSuggestionSelected['group_priority_name'],
] => {
  if (customPriority === PrimaryNavIdentifier.PAPER) return [CUSTOM_PRIORITY_PAPER, 'PAPER'];
  if (
    customPriority === PrimaryNavIdentifier.VENDORS ||
    customPriority === PrimaryNavIdentifier.REAL_WEDDINGS
  )
    return [CUSTOM_PRIORITY_MARKETPLACE, 'MARKETPLACE'];
  if (isGuest) return [BASE_PRIORITY_PRE_AUTH, 'COUPLE'];
  return [BASE_PRIORITY_POST_AUTH, 'SHOP'];
};

export default getCategoryPriority;
